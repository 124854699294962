/* TeamTypesModal.css */

/* Container for the modal content */
.team-types-modal-container {
  background-color: #f9f9f9;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* Each team type card */
.team-types-card {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.team-types-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Header area mimicking MessageSender history */
.team-type-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.25rem;
  background: #f1f3f5;
  border-bottom: 1px solid #e9ecef;
  cursor: pointer;
  transition: background 0.3s ease;
}

.team-type-header:hover {
  background: #e9ecef;
}

/* Left section: icon and team type name */
.team-types-main {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  flex: 1;
}

.type-name-input {
  flex: 1;
  padding: 0.5rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.95rem;
  transition: border-color 0.3s ease;
}

.type-name-input:focus {
  outline: none;
  border-color: #80bdff;
}

/* Collapse chevron button */
.chevron-button {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.25rem;
  color: #495057;
  /* Ensure it is flush right */
  margin-left: 10px;
  padding: 0.25rem;
  transition: transform 0.3s ease;
}

.chevron-button:focus {
  outline: none;
}

.chevron-button.expanded {
  transform: rotate(180deg);
}

/* Container for additional controls */
.header-actions {
  margin-left: 10px;
  display: flex;
  gap: 0.5rem;
}

/* Roles section shown when expanded */
.team-roles-container {
  padding: 1rem 1.25rem;
  background: #fff;
  border-top: 1px dashed #dee2e6;
  animation: fadeIn 0.3s ease forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.team-role-card {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 0.75rem;
}

.role-name-input {
  flex: 1;
  padding: 0.4rem 0.65rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.9rem;
  transition: border-color 0.3s ease;
}

.role-name-input:focus {
  outline: none;
  border-color: #80bdff;
}

.team-role-actions {
  display: flex;
  gap: 0.5rem;
}

/* Button for adding a new team type */
.add-team-type-button {
  margin-top: 1rem;
  align-self: stretch;
}

/* Save button container */
.save-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .team-types-modal-container {
    padding: 1rem;
    gap: 1rem;
  }
  .team-type-header {
    padding: 0.75rem 1rem;
  }
  .type-name-input {
    padding: 0.4rem 0.6rem;
    font-size: 0.9rem;
  }
  .team-roles-container {
    padding: 0.75rem 1rem;
  }
  .role-name-input {
    padding: 0.35rem 0.55rem;
    font-size: 0.85rem;
  }
}
